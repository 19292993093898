@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker__header {
  background-color: #fff;
  border: none;
}

.react-datepicker__current-month {
  font-family: 'Mulish';
  font-weight: 600;
  color: #071B3B;
  margin-bottom: 12px;
}

.react-datepicker__navigation--previous {
  left: 4px;
  top: 8px;
}

.react-datepicker__navigation--next {
  right: 6px;
  top: 6px;
}
.react-datepicker__navigation-icon::before {
  border-color: #222;
  border-width: 2px 2px 0 0;
  height: 6px;
  width: 6px;
}

.react-datepicker__day-name {
  font-size: 14px;
  color: #9CA4B1;
  line-height: 40px;
  width: 40px;
}

.react-datepicker__day {
  font-size: 14px;
  line-height: 45px;
  width: 45px;
  margin: 0;
}

.react-datepicker__day--today {
  font-weight: normal;
}

.react-datepicker__day:not(
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--selecting-range-start
):hover {
  border-radius: 0;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #f0f0f0;
}

.react-datepicker__day--in-range {
  border-radius: 0;
  background-color: #CCE0FF !important;
  color: #071B3B;
}

.react-datepicker__day--range-start:is(.react-datepicker__day--range-end) {
  border-radius: 4px;
}

.react-datepicker__day--range-start:is(.react-datepicker__day--range-end):hover {
  border-radius: 4px;
}

.react-datepicker__day--range-start,
.react-datepicker__day--selecting-range-start {
  border-radius: 4px 0 0 4px;
  background-color: #0063FF !important;
  color: #fff;
}

.react-datepicker__day--selecting-range-start,
.react-datepicker__day--range-start:hover {
  border-radius: 4px 0 0 4px;
}

.react-datepicker__day--range-end:hover {
  border-radius: 0 4px 4px 0;
}

.react-datepicker__day--in-selecting-range:not(
  .react-datepicker__day--selecting-range-start) {
    border-radius: 0;
    background-color: #CCE0FF !important;
    color: #071B3B;
}

.react-datepicker__day--range-end {
  border-radius: 0 4px 4px 0;
  background-color: #0063FF !important;
  color: #fff !important;
}

.react-datepicker__day--outside-month {
  color: #CDD1D8;
}

.react-datepicker__day--keyboard-selected {
  background-color: unset;
  color: inherit;
}

@media (min-width: 1280px) {
  .react-datepicker__day {
    line-height: 40px;
    width: 40px;
  }

  .react-datepicker__day-name {
    margin: 0;
  }

  .react-datepicker__month-container:first-of-type {
    margin-right: 20px;
  }
}
